<template>
    <YsPagination class="view view--user-list" ref="node" :total="total">
        <template #header>
            <el-input v-model="name" placeholder="请输入用户姓名查找" clearable />
            <el-button type="primary" @click.prevent="query">查询</el-button>
            <el-button type="success" @click="addUser(1)">新增用户</el-button>
        </template>
        <el-table :data="userlist" height="100%" style="width: 100%;" border>
            <el-table-column label="序号" type="index" width="100" />
            <el-table-column label="用户名称" width="180">
                <template #default="scope">
                    <el-tag>{{ scope.row.name }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="用户角色">
                <template #default="scope">
                    <el-tag>
                        {{ scope.row.role == 'partner' ? '合伙人' : '经销商' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="电话号码">
                <template #default="scope">
                    <span>{{ scope.row.mobile }}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template #default="scope">
                    <el-tag class="ml-2 user-status" type="success" v-if="scope.row.status == 1" @click="handleStatus(scope.row)">
                        启用</el-tag>
                    <el-tag class="ml-2 user-status" type="info" v-else @click="handleStatus(scope.row)">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template #default="scope">
                    <el-button size="small" @click="addUser(2, scope.row)">
                        编辑</el-button>
                    <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </YsPagination>
    <el-dialog v-model="dialogFormVisible" :title="type == 1 ? '新增' : '编辑'" center width="480px">
        <el-form :model="addForm" :rules="formRule" ref="ruleFormRef" status-icon>
            <el-form-item label="用户名称" :label-width="formLabelWidth" prop="name">
                <el-input v-model="addForm.name" placeholder="请填写用户名" autocomplete="off" clearable />
            </el-form-item>
            <el-form-item label="手机号码" :label-width="formLabelWidth" prop="mobile">
                <el-input v-model="addForm.mobile" :disabled="type == 1 ? false : true" placeholder="请填写手机号码"
                    autocomplete="off" clearable />
            </el-form-item>
            <el-form-item label="用户密码" :label-width="formLabelWidth" prop="password">
                <el-input v-model="addForm.password" type="password" placeholder="请填写密码" clearable show-password
                    autocomplete="off" />
            </el-form-item>
            <el-form-item label="用户角色" :label-width="formLabelWidth" prop="role">
                <el-select v-model="addForm.role" placeholder="请选择用户角色" style="width: 100%">
                    <el-option label="合伙人" value="partner" />
                    <el-option label="经销商" value="ordinary" />
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">关闭</el-button>
                <el-button type="primary" @click="handleSubmit">
                    保存
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { userListApi, userAddApi, userDeleteApi, userStatusApi, userUpdateApi } from '@/api/user.js'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    data() {
        return {
            total: 0,
            type: 1,
            name: '',
            // role:'partner,ordinary'
            userlist: [],
            dialogFormVisible: false,
            addForm: {
                name: "",
                mobile: '',
                password: '',
                role: ""
            },
            formLabelWidth: '80px'
        }
    },
    computed: {
      formRule() {
        let rule = {
          name: [
            { required: true, message: '请输入用户名称', trigger: 'blur' },
          ],
          mobile: [
            { required: true, message: '请输入手机号码', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                let reg = /^1[3-9]\d{9}$/
                if (value && !reg.test(value)) {
                  callback(new Error('手机号码格式不正确'));
                } else {
                  callback()
                }
              }, trigger: 'blur'
            },
          ],
          role: [
            { required: true, message: '请选择用户角色', trigger: 'change' },
          ]
        }
        if (this.type === 1) {
          rule.password = [ { required: true, message: '请输入用户密码', trigger: 'blur' } ]
        }
        return rule;
      }
    },
    methods: {
        query() {
            this.$refs.node.pageNo = 1;
            this.$refs.node.queryFunc()
        },

        // 获取用户列表数据
        async getList(obj) {
            let res = await userListApi({ ...obj, name: this.name })
            if (res.meta.code == 0) {
                this.userlist = res.data.rows
                this.total = res.data.count
            }
        },
        // 显示添加修改用户弹窗
        addUser(type, data) {
            this.type = type
            if (type == 1) {
                this.addForm = {
                    name: "",
                    mobile: '',
                    password: '',
                    role: ""
                }
            } else {
                this.addForm = data
            }

            this.dialogFormVisible = true

        },
        // 保存或修改用户信息
        handleSubmit() {
            this.$refs.ruleFormRef.validate(async (valid, fields) => {
                if (valid) {
                    if (this.type == 1) {
                        let res = await userAddApi(this.addForm)
                        if (res.meta.code == 0) {
                            this.$message.success('人员添加成功！')
                            this.dialogFormVisible = false
                            this.$refs.node.queryFunc()
                        }
                    } else {
                        let res = await userUpdateApi(this.addForm)
                        if (res.meta.code == 0) {
                            this.dialogFormVisible = false
                            this.$refs.node.queryFunc()
                            ElMessage({
                                type: 'success',
                                message: `修改成功！`,
                            })
                        }
                    }

                } else {
                    console.log('error submit!', fields)
                }
            })
        },
        // 修改用户状态
        handleStatus(row) {
            let str = row.status == 1 ? '禁用' : "启用"
            ElMessageBox.confirm(
                `你确定要${str}该用户么？`,
                '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }
            )
                .then(async () => {
                    let params = {
                        id: row.id,
                        status: row.status == 1 ? 0 : 1
                    }
                    let res = await userStatusApi(params)
                    if (res.meta.code == 0) {
                        this.$refs.node.queryFunc()
                        ElMessage({
                            type: 'success',
                            message: `${str}成功！`,
                        })
                    }
                })
                .catch(() => {
                  console.log("cancel");
                })
        },

        handleEdit() { },
        handleDelete(index, row) {
            ElMessageBox.confirm(
                '你确定要删除该用户么？',
                '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }
            )
                .then(async () => {
                    let res = await userDeleteApi(row.id)
                    if (res.meta.code == 0) {
                        this.$refs.node.queryFunc()
                        ElMessage({
                            type: 'success',
                            message: '删除成功！',
                        })
                    }
                })
                .catch(() => {
                  console.log("cancel");
                })
        }



    },
}
</script>

<style lang='less' scoped>
.content-box {
    margin: 20px;
    background-color: #ffffff;
}

.view--user-list {
  .user-status {
    cursor: pointer;
  }
}
</style>
